import React from 'react'
import News from '../../../react4xp/components/News'
import SectionHeader from '../../../react4xp/components/SectionHeader'
import NewsCard from '../../../react4xp/components/NewsCard'


function NewsPart({ label, heading, subHeading, items, strings }) {
  return (
    <News
      sectionHeader={<SectionHeader
        layout='horizontal'
        title={heading}
        subtitle={subHeading}
        labelText={label}
        bulletColor='accent-1'
        strings={strings}
      />}
      cards={items.map(itemObj => (
        <NewsCard
          href={itemObj.href}
          imgSrc={itemObj.imgSrc}
          imgSrcMedium={itemObj.imgSrcMedium}
          imgSrcSmall={itemObj.imgSrcSmall}
          imgAlt={itemObj.imgAlt}
          imgWidth={1920}
          labelBulletColor='accent-1'
          labelText={itemObj.labelText}
          title={itemObj.title}
          buttonText={itemObj.buttonText}
        />
      ))}
    />
  )
}

export default (props) => <NewsPart {...props} />
